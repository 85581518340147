import styles from "./AgreementBulkConfirm.module.css";
import { Button, Loader, Text } from "@mantine/core";
import { BeatLoader } from "react-spinners";
import { useState } from "react";

function AgreementBulkConfirm({
  bulkAgreementData,
  onPrevious,
  onSubmit,
  loading,
}) {
  const [validateFlag, setValidateFlag] = useState(false);
  const headers = Object.keys(bulkAgreementData[0] ? bulkAgreementData[0] : []);

  const requiredFields = [
    "Template Id",
    "Signature Type",
    "Agreement Type",
    "Type Of Agreement",
    "Title Of Agreement",
    "Requesting User Name",
    "Requesting User Email",
    "No Of Years",
    "Reminder Time In Days",
    "Location",
    "First Party Name",
    "First Party Email",
    "Second Party Name",
    "Second Party Email",
  ];

  // const validate = ({ property, value }) => {
  //   if (property.startsWith("ph_") && (value === null || value === "")) {
  //     if(!validateFlag) setValidateFlag(true);
  //     return true;
  //   } else if (
  //     requiredFields.includes(property) &&
  //     (value === null || value === "")
  //   ) {
  //     if(!validateFlag) setValidateFlag(true);
  //     return true;
  //   }
  //   return false;
  // };

  const rows = bulkAgreementData?.map((element, i) => (
    <tr key={i}>
      {headers?.map((header, j) => (
        <td
          key={j}
          className={styles.tdandthCss}
          // style={{
          //   backgroundColor: validate({
          //     property: header,
          //     value: element[header],
          //   })
          //     ? "#ff9999"
          //     : "",
          // }}
        >
          {element[header]}
        </td>
      ))}
    </tr>
  ));

  return (
    <div style={{ marginLeft: "20px" }}>
      <Text 
      style={{
        color: "#46BDE1",
      }}
       className="mb-2">Uploaded Data Verification:</Text>
      <table className={styles.tableCss}>
        <thead>
          <tr>
            {headers?.map((header, i) => (
              <th key={i} className={styles.tdandthCss}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      <div className="my-4 flex justify-between mt-4">
        {loading? <Loader size={30}/> : <>
        <Button 
        onClick={()=>onPrevious()} 
        style={{
          backgroundColor: "#46BDE1",
        }}
        >previous</Button>
        <Button
          className="mx-4"
          color="green"
          // disabled={ validateFlag}
          onClick={onSubmit}
          style={{
            backgroundColor: "#46BDE1",
          }}
        >
         
            <Text>submit</Text>
        </Button>
        </>}
      </div>
    </div>
  );
}

export default AgreementBulkConfirm;
