/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { AiOutlineSearch } from "react-icons/ai";

import {
  ActionIcon,
  Badge,
  Button,
  Input,
  Pagination,
  Select,
  Skeleton,
  Table,
  Text,
  CloseButton,
  TextInput,
  Anchor,
  Modal,
} from "@mantine/core";
import { BeatLoader } from "react-spinners";
import { DatePicker } from "@mantine/dates";
import {
  Eye,
  History,
  Plus,
  Search,
  SortAscending,
  SortDescending,
} from "tabler-icons-react";
import { formatISO } from "date-fns";
import { useDebouncedValue } from "@mantine/hooks";
import {
  deleteAgreementBulk,
  getAgreementsSigningCsvDownload,
} from "../../utilities/apis/agreements";
import {
  getValueForInput,
  loadingStates,
  csvDownload,
  formatDate,
  formatIndianNumber,
} from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getAgreementsList,
  getAgreementTypesApi,
  getAgreementsCsvDownload,
} from "../../utilities/apis/agreements";
import UserAvatarView from "../../components/UserAvatarView";
import DashboardNotifications from "../DashboardNotifications";
import { agreementStatusColors } from "../../utilities/enums";
import AgreementAnalytics from "../../components/AgreementAnalytics/AgreementAnalytics";
import colors, { themeColor } from "../../utilities/design";
import AgreementGraph from "./AgreementGraph";
import Styles from "./AgreementsListingPage.module.css";
import { INDIA_STATES_UNIONS } from "./AgreementForm2";
import HamburgerMenu from "../HamburgerMenuAgreements";
import HamburgerMenuAgreements from "../HamburgerMenuAgreements";
import AgreementAnalytics1 from "../../components/AgreementAnalytics/AgreementAnalytics1";
import { getAgreementsInSigningList } from "../../utilities/apis/agreements";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";
import AgreementGraph2 from "./AgreementGraph2";
import RelatedAgreementModal from "./agreementRelatedModal";
import AgreementAuditLogModal from "./AgreementAuditLogModal";
import { IconArrowDownBar, IconArrowUpBar } from "@tabler/icons-react";

const statusOptions = [
  {
    label: "Pending with Company",
    value: "Pending with Company",
  },
  {
    label: "TP",
    value: "TP",
  },
];

const estampOption = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const AgreementsInSigning = () => {
  const currentUser = useSelector(selectUserData);
  const [open, setOpen] = useState(false);
  const [listing, setListing] = useState({
    list: null,
    agreementsCount: null,
    loaded: false,
    sortBy: "createdAt",
    sortDirection: "desc",
    searchString: "",
    statusType: "",
    estamp: null,
    agreementType: null,
    location: null,
    startDate: null,
    endDate: null,
  });
  const [agreementTypeOptions, setAgreementTypeOptions] = useState([]);
  const [uiConfigs, setUiConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    page: 1,
  });
  const [debouncedSearchInput] = useDebouncedValue(listing.searchString, 500);
  const [agreementPayload, setAgreementPayload] = useState({
    page: 1,
    sortByOptions: {
      createdAt: "desc",
    },
    filterOptions: {},
  });
  const [exportButtonLoading, setExportButtonLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [agreementId, setAgreementId] = useState(null);
  const handleSearchClick = (id) => {
    // Set the showModal state to true when the button is clicked
    setShowModal(true);
    // Pass the row id to the AgreementAuditLogModal component
    setAgreementId(id);
  };
  const [showModalRelated, setShowModalRelated] = useState(false);
  const [relatedAgreementId, setRelatedAgreementId] = useState(null);
  const handlePlusClick = (id) => {
    setShowModalRelated(true);
    setRelatedAgreementId(id);
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleCheckboxChange = (id, isChecked) => {
    setSelectedIds((prevSelectedIds) => {
      if (isChecked) {
        return [...prevSelectedIds, id];
      } else {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      }
    });
  };

  const deleteHandler = async () => {
    console.log("Deleting agreements with IDs:", selectedIds);
    const agreementIds = selectedIds;
    setDeleteLoading(true);
    const response = await apiWrapWithErrorWithData(
      deleteAgreementBulk({ agreementIds })
    );
    if (response?.success) {
      showNotification({
        color: "green",
        title: "Agreements",
        message: "Agreement deleted successfully.",
      });
      setOpen(false);
      navigate("/app");
    } else {
      showNotification({
        color: "red",
        title: "Agreements",
        message: "Unable to delete agreement",
      });
    }
    setDeleteLoading(false);
  };

  const fetchAgreementsList = async ({ page }) => {
    let filterOptions = {};
    if (listing.startDate && listing.endDate) {
      filterOptions["createdAt"] = {
        gte: formatISO(listing.startDate),
        lte: formatISO(listing.endDate),
      };
    } else if (listing.startDate || listing.endDate) {
      showNotification({
        title: "Agreement notification",
        message: "please select start and end date",
        color: "red",
      });
      return;
    }
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.LOADING,
    });
    const payload = {
      page: page ? page : uiConfigs.page,
      sortByOptions: {
        [listing.sortBy]: listing.sortDirection,
      },
    };
    if (listing.agreementType && listing.statusType && listing.estamp) {
      filterOptions = {
        ...filterOptions,
        typeOfAgreement: {
          equals: listing.agreementType,
        },
        status: {
          equals: listing.statusType,
        },
        agreementType: {
          equals: listing.estamp === "Yes" ? "eStamp" : "normal",
        },
      };
    } else if (listing.agreementType || listing.statusType || listing.estamp) {
      if (listing.agreementType) {
        filterOptions["typeOfAgreement"] = {
          equals: listing.agreementType,
        };
      }
      if (listing.statusType) {
        filterOptions["status"] = {
          equals: listing.statusType,
        };
      }
      if (listing.estamp) {
        filterOptions["agreementType"] = {
          equals: listing.estamp === "Yes" ? "eStamp" : "normal",
        };
      }
    }
    if (listing.location) {
      filterOptions["location"] = listing.location;
    }

    payload["filterOptions"] = filterOptions;

    setAgreementPayload(payload);
    const response = await apiWrapWithErrorWithData(
      getAgreementsList({ ...payload })
    );
    if (response?.success && response?.agreements) {
      setListing({
        ...listing,
        list: response?.agreements,
        agreementsCount: response.agreementsCount,
        loaded: true,
      });
    }
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.NO_ACTIVE_REQUEST,
    });
  };
  const data = listing?.list;

  useEffect(() => {
    getAgreementTypes();
  }, []);

  useEffect(() => {
    if (listing.agreementType || listing.statusType || listing.estamp) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else if (listing.estamp) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else if (listing.startDate && listing.endDate) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else {
      if (listing.startDate === null && listing.endDate === null) {
        fetchAgreementsList({});
      }
    }
  }, [listing.sortBy, listing.sortDirection]);

  useEffect(() => {
    fetchAgreementsList({});
  }, [uiConfigs.page]);

  const navigate = useNavigate();
  const onViewClick = (agreementId) => {
    // navigate(`/app/agreements/details/${agreementId}`);
    // const url =
    navigate(`/app/agreements_manager/agreements/details/${agreementId}`);
    // window.open(url, "noopener,noreferrer");
  };

  const getAgreementTypes = async () => {
    const resp = await apiWrapWithErrorWithData(getAgreementTypesApi());
    if (resp?.success && resp?.typesOfAgreement) {
      const uniqueValues = Array.from(new Set([...resp.typesOfAgreement]));
      const temp = uniqueValues.map((item) => ({ label: item, value: item }));
      setAgreementTypeOptions([...agreementTypeOptions, ...temp]);
    }
  };
  const handleCsvDownload = async () => {
    try {
      setExportButtonLoading(true);
      const response = await apiWrapWithErrorWithData(
        getAgreementsSigningCsvDownload({ ...agreementPayload })
      );
      if (response.success) {
        if (response?.agreements?.length > 0) {
          const columnHeaders = Object.keys(response.agreements[0]);
          csvDownload({
            data: response.agreements,
            headers: columnHeaders,
            name: "agreement",
          });
        } else {
          showNotification({
            title: "Agreement csv download",
            message: "No agreement available",
            color: "red",
          });
        }
      }
      setExportButtonLoading(false);
    } catch (error) {
      setExportButtonLoading(false);
      showNotification({
        title: "Agreement csv download",
        message: "Could not load agreement csv",
        color: "red",
      });
    }
  };

  const clearHandler = async () => {
    try {
      setUiConfigs({
        ...uiConfigs,
        page: 1,
        loading: loadingStates.LOADING,
      });

      const payload = {
        page: 1,
        sortByOptions: {
          [listing.sortBy]: listing.sortDirection,
        },
        filterOptions: {},
      };

      setAgreementPayload(payload);
      const response = await apiWrapWithErrorWithData(
        getAgreementsList({ ...payload })
      );

      if (response?.success && response?.agreements) {
        setListing({
          ...listing,
          searchString: "",
          agreementType: "",
          statusType: null,
          estamp: "",
          location: null,
          startDate: null,
          endDate: null,
          list: response?.agreements,
          agreementsCount: response.agreementsCount,
          loaded: true,
          estamp: null,
        });
      }

      setUiConfigs({
        ...uiConfigs,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    } catch (error) {
      console.log("Agreement list fetching error: ", error);
    }
  };
  const [sortByOptions, setSortByOptions] = useState({
    key: "createdAt",
    value: "desc",
  });
  const handleKeyChange = (val) => {
    const newSortOption = { key: val, value: sortByOptions.value };
    setSortByOptions(newSortOption);
  };
  const toggleOrder = () => {
    const newSortOption = {
      key: sortByOptions.key,
      value: sortByOptions.value === "asc" ? "desc" : "asc",
    };
    setSortByOptions(newSortOption);
  };
  const [filters, setFilter] = useState({
    typeOfAgreement: null,
    mapBusinessunit: null,
  });
  const [pageSize, setPageSize] = useState(10);
  const [reviewAgreementpage, setReviewAgreementpage] = useState(1);
  let serialNumber = (reviewAgreementpage - 1) * pageSize + 1;
  const [configs, setConfigs] = useState({
    loading: loadingStates.LOADING,
    data: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filtersForData = await Object.fromEntries(
          Object.entries(filters).filter(([key, value]) => value !== null)
        );
        const sortOption = { [sortByOptions.key]: sortByOptions.value };
        const response = await apiWrapWithErrorWithData(
          getAgreementsInSigningList({
            filterOptions: filtersForData,
            page: reviewAgreementpage,
            pageSize: pageSize,
            sortByOptions: sortOption,
          })
        );
        if (response) {
          setConfigs({
            loading: loadingStates.NO_ACTIVE_REQUEST,
            data: response,
          });
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, [reviewAgreementpage, pageSize, filters, sortByOptions]);
  console.log("filters: ", filters);

  return (
    <>
      <Modal
        overflow="inside"
        opened={showModalRelated}
        onClose={() => {
          setShowModalRelated(false);
        }} // Call the handler on modal close
        size="calc(80vw)"
      >
        <RelatedAgreementModal agreementId={relatedAgreementId} />
      </Modal>
      <Modal
        overflow="inside"
        opened={showModal}
        onClose={() => setShowModal(false)} // Call the handler on modal close
        size="calc(80vw)"
      >
        <AgreementAuditLogModal agreementId={agreementId} />
      </Modal>
      {configs.loading === loadingStates.NO_ACTIVE_REQUEST && (
        <>
          <div
            className="flex"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              alignItems: "center",
              marginBottom: "12px",
            }}
          >
            <h1
              style={{
                fontSize: "22px",
                fontWeight: "600",
                letterSpacing: "1px",
                textTransform: "lowercase",
                fontFamily: "lexend",
                whiteSpace: "nowrap",
                paddingBottom: "13px",
                position: "relative",
                color: "black",
                // margin: "0 auto"
              }}
            >
              Agreements pending signature
            </h1>

            <HamburgerMenuAgreements />
          </div>
          <div className="flex flex-row flex-wrap w-full">
            <AgreementAnalytics1
              filter={filters}
              setFilter={setFilter}
              configs={configs}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <AgreementGraph2 configs={configs} />
          </div>
        </>
      )}
      {(currentUser.loginAs.id === 4 ||
        currentUser.loginAs.id === 2 ||
        currentUser.loginAs.id === 1) && (
        <>
          <div className="flex flex-col">
            <div className="flex justify-between my-4">
              <Text>table view</Text>
              <div className="flex float-right">
                <div className="flex">
                  <Select
                    value={sortByOptions.key}
                    onChange={(val) => handleKeyChange(val)}
                    data={[
                      { label: "created at", value: "createdAt" },
                      { label: "end date", value: "dueDateOfContract" },
                      { label: "type of agreement", value: "typeOfAgreement" },
                      {label: "agreement value", value: "agreementValue"},
                      { label: "ain", value: "id" },
                    ]}
                  />
                  <ActionIcon className="mx-2" onClick={toggleOrder}>
                    {sortByOptions.value === "asc" ? (
                      <SortAscending size={32} />
                    ) : (
                      <SortDescending size={32} />
                    )}
                  </ActionIcon>
                </div>
                <Button
                  className="mx-2 btn btn-success btn-lg float-right"
                  disabled={exportButtonLoading}
                  onClick={handleCsvDownload}
                  style={{
                    backgroundColor: "#46BDE1",
                  }}
                >
                  export
                </Button>
              </div>
            </div>
          </div>

          {configs.loading === loadingStates.LOADING && (
            <div className="flex flex-col">
              {Array.from({ length: 10 }).map((i) => (
                <Skeleton
                  key={i}
                  height={30}
                  radius="md"
                  className="my-1 w-full"
                />
              ))}
              <div className="flex flex-row justify-center">
                <Skeleton height={40} radius="md" className="w-40" />
              </div>
            </div>
          )}
          {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
            configs.data?.agreements && (
              <div className="flex flex-col">
                <Table striped>
                  <thead>
                    <tr>
                      <th>sr no.</th>
                      <th>ain</th>
                      <th>business unit</th>
                      <th>type</th>
                      <th>{"annual value (₹)"}</th>
                      {/* <th>First Party</th> */}
                      <th>second party</th>
                      {/* <th>direct signature </th> */}
                      <th>editor</th>
                      <th>{"ageing (days)"}</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {configs.data.agreements.map((row) => {
                      let ageingColor;
                      if (row.ageing <= 3) {
                        ageingColor = "rgb(70, 189, 225)";
                      } else if (row.ageing <= 7) {
                        ageingColor = "rgb(70, 189, 225)";
                      } else if (row.ageing <= 10) {
                        ageingColor = "#FDAE38";
                      } else {
                        ageingColor = "#F75435";
                      }

                      return (
                        <tr key={row.id}>
                          <td>{serialNumber++}</td>
                          <td>{`agreement-${row.id}`}</td>
                          <td>{row.businessUnit}</td>
                          <td>{row.agreementData.json.typeOfAgreement}</td>
                          <td>{formatIndianNumber(row.agreementValue)}</td>
                      <td>
                          {row.agreementData.json.brandName}
                          <br/>
                          <span className="font-light">{`(${row.agreementData?.json?.secondParty.name|| "-"})`}</span>
                        </td>
                          {/* <td>{row.pendingWith}</td> */}
                          <td>
                            <Badge>{row.editorName}</Badge>
                          </td>
                          <td
                            style={{ color: ageingColor }}
                          >{`${row.ageing}`}</td>
                          <td style={{ textAlign: "center" }}>
                            {row.relationshipWith ? (
                              <div className="flex flex-row ">
                                <ActionIcon
                                  onClick={() => {
                                    handlePlusClick(row.id);
                                  }}
                                  color="white"
                                >
                                  <Plus color="#46BDE1" size={24} />
                                </ActionIcon>
                              </div>
                            ) : (
                              <span className="mr-2">{"--"}</span>
                            )}
                          </td>
                          <td>
                            <div className="flex flex-row mr-4">
                              <ActionIcon
                                onClick={() => {
                                  onViewClick(row.id);
                                }}
                                color="white"
                              >
                                <Eye color="#46BDE1" size={24} />
                              </ActionIcon>
                              {/* )} */}
                            </div>
                          </td>
                          <td>
                            <div className="flex flex-row mr-4">
                              <ActionIcon
                                onClick={() => handleSearchClick(row.id)}
                                color="white"
                              >
                                <History color="#46BDE1" size={24} />
                              </ActionIcon>

                              {/* )} */}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
          !configs.data?.agreements?.length ? (
            <div
              className="flex justify-center items-center"
              style={{
                minHeight: "300px",
              }}
            >
              No agreements
            </div>
          ) : null}
          {/* </div> */}
          {configs.loading === loadingStates.NO_ACTIVE_REQUEST ? (
            <div className="flex flex-row justify-center my-4">
              <Pagination
                page={reviewAgreementpage}
                onChange={(page) => {
                  setReviewAgreementpage(page);
                }}
                total={Math.ceil(configs.data?.agreementsCount / pageSize)}
              />
              <Text className="mx-4">select page size</Text>
              <Select
                className="w-20"
                data={["10", "20", "50"]}
                value={pageSize + ""}
                onChange={(val) => setPageSize(parseInt(val))}
              />
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default AgreementsInSigning;
