import { TextInput, Select, Button, Radio, MultiSelect } from "@mantine/core";
import React, { useState, useEffect } from "react";
import styles from "./ServiceProviderForm.module.css";
import {
  getBussinessUnit,
  serviceProviderUserRole,
  updateServiceProvider,
} from "../../utilities/apis/serviceProvider";
import { existsAndLength, validateEmail } from "../../utilities/utilities";
import { showNotification } from "@mantine/notifications";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";

const UpdateUser = ({
  selectUser,
  selectUserData,
  setEditModal,
  setSelectedUser,
  setSelectedUserData,
}) => {
  console.log(selectUser, selectUserData);
  const [updatedData, setUpdatedData] = useState({
    email: selectUserData.email,
    name: selectUserData.name,
    enabled: selectUserData.enabled,
    selectUserRoles: selectUserData.userRole,
    selectedBusinessUnit: parseInt(selectUserData.mapBusinessunit),
    error: {},
  });
  console.log(updatedData);
  const changeHandler = (value) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      enabled: value === "enabled",
    }));
  };
  const [userRoleOptions, setUserRoleOptions] = useState([]);

  useEffect(() => {
    const fetchUserRoleOptions = async () => {
      try {
        const response = await serviceProviderUserRole();

        // console.log("User Role Response:", response.data.roleList);

        if (response && response.data) {
          const { data } = response;

          setUserRoleOptions(data.roleList);
        } else {
          console.error("Invalid user role response format:", response);
        }
      } catch (error) {
        console.error("Error fetching user role options:", error);
      }
    };

    fetchUserRoleOptions();
  }, []);
  const [businessunitOptions, setBussinessUnitOptions] = useState([]);
  const roleTypeOptions = userRoleOptions.map((option) => ({
    label: option.name,
    value: option.name,
  }));
  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const data  = await apiWrapWithErrorWithData( getBussinessUnit());

        if (data) {
          // console.log("Inside", data.businessunitList);
          setBussinessUnitOptions(data.businessunitList || []);
        } else {
          console.error("Invalid business unit response format:", response);
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };

    fetchBusinessUnits();
  }, []);
  // const businessUnitOptions = businessunitOptions.map((option, index) => ({
  //   label: option.name,
  //   value: option.id,
  // }));
  // console.log(businessUnitOptions);

  const validate = () => {
    const keys = {};
    if (!existsAndLength(updatedData.name)) {
      keys.name = "Please check name.";
    }
    if (
      !existsAndLength(updatedData.email) ||
      !validateEmail(updatedData.email)
    ) {
      keys.email = "Please check email.";
    }
    if (!existsAndLength(updatedData.selectUserRoles)) {
      keys.userRole = "Please fill User Role";
    }
    if (updatedData.selectedBusinessUnit === null) {
      keys.businessUnits = "Please fill Business Unit";
    }
    if (Object.keys(keys).length) {
      showNotification({
        color: "red",
        title: "Service Record",
        message: "Make sure all fields are filled properly.",
      });
      setUpdatedData({ ...updatedData, error: keys });
      return false;
    }
    return true;
  };

  const rolesoptions = [
    "System-Admin",
    "Client-Admin",
    "Initiator",
    "Editor",
    "Reviewer",
    "Approver",
    "Sign-Auth",
  ];
  const values = [1, 2, 3, 4, 5, 6, 7];
  function getSelectedValues(selectedRoles, roles, values) {
    return selectedRoles.map((role) => {
      const index = roles.indexOf(role);
      return values[index];
    });
  }

  const updateUserData = async () => {
    if (validate()) {
      try {
        const role = updatedData.selectUserRoles.replace(/, /g, "|");
        const selectedOptions = updatedData.selectUserRoles.split(", ");
        const selectedValues = getSelectedValues(
          selectedOptions,
          rolesoptions,
          values
        );
        const userRoles = selectedValues.join(",");
        const sendApiData = {
          id: selectUser,
          role: role,
          userRoles: userRoles,
          name: updatedData.name,
          email: updatedData.email,
          businessUnit: updatedData.selectedBusinessUnit,
          enabled: updatedData.enabled,
        };
        await updateServiceProvider({ serviceProviderData: sendApiData });
        showNotification({
          color: "green",
          title: "update user details",
          message: "user details updated",
        });
        setEditModal(false);
        setSelectedUser(0);
        setSelectedUserData({});
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (error) {
        showNotification({
          color: "red",
          title: "update user details",
          message: "user details not updated",
        });
      }
    }
  };

  return (
    <div className="flex flex-col px-4 pb-8">
      <div className={styles.title}>update user details</div>

      <div className=" grid grid-cols-2 gap-x-4 mt-8">
        <TextInput
          placeholder="Enter Name"
          label="Name"
          required
          value={updatedData.name}
          onChange={(e)=>setUpdatedData({...updatedData, name: e.target.value})}
          error={updatedData.error.name}
        />
        <TextInput
          // className="mt-8"
          placeholder="Enter Email"
          label="Email"
          type="email"
          value={updatedData.email}
          onChange={(e)=>setUpdatedData({...updatedData, email: e.target.value})}
          required
          error={updatedData.error.email}
        />

        <MultiSelect
          label="User Role"
          className="mt-8"
          placeholder="Select user role"
          required
          value={updatedData.selectUserRoles
            ?.split(", ")
            .map((role) => role.trim())}
          data={roleTypeOptions}
          onChange={(val) => {
            // console.log(val);
            setUpdatedData((data) => ({
              ...data,
              selectUserRoles: val.join(", "),
            }));
          }}
          error={updatedData.error.userRole}
        />

        <Select
          className="mt-8"
          placeholder="Business Unit/Location"
          label="Business Unit/Location"
          required
          data={businessunitOptions}
          value={updatedData.selectedBusinessUnit}
          onChange={(selectedBU) => {
            setUpdatedData((data) => ({
              ...data,
              selectedBusinessUnit: selectedBU,
            }));
          }}
          error={updatedData.error.businessUnits}
        />
      </div>
      <Radio.Group
        className="mt-8"
        label="User Permission"
        spacing="xs"
        withAsterisk
        value={updatedData.enabled ? "enabled" : "disabled"}
        onChange={changeHandler}
      >
        <Radio value="enabled" label="Enabled" />
        <Radio value="disabled" label="Disabled" />
      </Radio.Group>

      <div className="flex justify-end">
        <Button
          style={{
            backgroundColor: "#46BDE1",
            borderRadius: "0.5rem",
            color: "#F5F5F5",
          }}
          onClick={() => updateUserData()}
        >
          <span> Save</span>
        </Button>
      </div>
    </div>
  );
};

export default UpdateUser;
