import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Table } from '@mantine/core';

// Register necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Estamps = () => {
  // Data for the chart
  const data = [
        {
            "Denomination": "100",
            "State": "Maharashtra",
            "Allocated": 136,
            "Consumed": 116
        },
        {
            "Denomination": "100",
            "State": "Uttar Pradesh",
            "Allocated": 80,
            "Consumed": 72
        },
        {
            "Denomination": "100",
            "State": "Karnataka",
            "Allocated": 72,
            "Consumed": 72
        },
        {
            "Denomination": "100",
            "State": "Tamil Nadu",
            "Allocated": 59,
            "Consumed": 59
        },
        {
            "Denomination": "100",
            "State": "Delhi",
            "Allocated": 50,
            "Consumed": 42
        },
        {
            "Denomination": "100",
            "State": "Haryana",
            "Allocated": 39,
            "Consumed": 39
        },
        {
            "Denomination": "100",
            "State": "West Bengal",
            "Allocated": 29,
            "Consumed": 29
        },
        {
            "Denomination": "100",
            "State": "Madhya Pradesh",
            "Allocated": 17,
            "Consumed": 17
        },
        {
            "Denomination": "100",
            "State": "Punjab",
            "Allocated": 10,
            "Consumed": 10
        },
        {
            "Denomination": "100",
            "State": "Chandigarh",
            "Allocated": 6,
            "Consumed": 0
        }
    ];

  // Prepare chart data
  const labels = data.map(item => item.State);
  const allocatedData = data.map(item => item.Allocated);
  const consumedData = data.map(item => item.Consumed);
  const availableData = data.map(item => item.Allocated - item.Consumed); // Calculate available count

  const chartData = {
    labels,
    datasets: [
      {
        label: 'allocated',
        data: allocatedData,
        backgroundColor: '#6699ff', // Total in blue
        barThickness: 35,
        categoryPercentage: 0.3,
      },
      {
        label: 'consumed',
        data: consumedData,
        backgroundColor: '#ff6666', // Used in red
        barThickness: 35,
        categoryPercentage: 0.3,
      },
      {
        label: 'available',
        data: availableData,
        backgroundColor: '#66ff66', // Available in green
        barThickness: 35,
        categoryPercentage: 0.3,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        // Adjust the spacing for each bar group
        stacked: false, // Don't stack the bars
        title: {
          display: true,
          text: 'states',
        },
        grid: {
          display: false, // Disable grid lines
        },
      },
      y: {
        // Start the y-axis at 0 and show all values correctly
        beginAtZero: true,
        title: {
          display: true,
          text: 'count',
        },
        grid: {
          display: false, // Disable grid lines
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Estamps Data by State',
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  return (
    <div>
      <h2>Estamps</h2>
      <div style={{
        height: "600px"
      }} >
      <Bar style={{
        height: "600px"
      }} data={chartData} options={options} />
      </div>
      <Table className='mt-12 p-4 ' striped>
      <thead>
                <tr>
                  <th>state</th>
                  <th>denomination</th>
                  <th>total</th>
                  <th>consumed</th>
                  <th>available</th>
                  
                </tr>
              </thead>
              <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.State}</td>
              <td>{item.Denomination}</td>
              <td>{item.Allocated}</td>
              <td>{item.Consumed}</td>
              <td>{item.Allocated - item.Consumed}</td> {/* Available = Allocated - Consumed */}
            </tr>
          ))}
        </tbody>

      </Table>
    </div>
  );
};

export default Estamps;
