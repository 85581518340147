import React, { useEffect, useState } from "react";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getDraftAgreements } from "../../utilities/apis/agreements";
import { ActionIcon, Table, Text } from "@mantine/core";
import { formatDate, formatIndianNumber, formatToIndianNumbering } from "../../utilities/utilities";
import { Edit } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";

const DraftAgreements = () => {
  const [configs, setConfigs] = useState({
    loading: true,
    page: 1,
    PageSize: 10,
  });
  const navigate = useNavigate();
  const [agreements, setAgreements] = useState([]);
  useEffect(() => {
    const fetchDraftAgreements = async () => {
      const response = await apiWrapWithErrorWithData(getDraftAgreements({page: configs.page, pageSize: configs.PageSize}));
      if (response.success) {
        setConfigs({
          ...configs,
          loading: false,
        });
        setAgreements(response.agreements);
      }
    };
    fetchDraftAgreements();
  }, []);

  return (
    <div>
      <div className="flex justify-between my-4">
        <Text>draft agreements</Text>
      </div>
      {!configs.loading &&
      <div>
        <Table striped>
          <thead>
            <tr>
              <th>ain</th>
              <th>type</th>
              <th>title</th>
              <th>{"annual value (₹)"}</th>
              <th>created at</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {agreements.map(agreement=>
            <tr key={agreement.id}>
                <td>{agreement.id}</td>
                <td>{agreement.typeOfAgreement}</td>
                <td>{agreement.titleOfAgreement}</td>
                <td>{formatIndianNumber(agreement.agreementValue)}</td>
                <td>{formatDate(agreement.createdAt)}</td>
                <td>
                    <ActionIcon 
                    onClick={() => {
                        navigate("/app/agreements_manager/agreements/edit", {
                          state: {
                            // agreementId: agreementData.agreementId,
                            agreementId: agreement.id,
                          },
                        });
                      }}
                    >
                        <Edit color="#46BDE1" size={24}/>
                    </ActionIcon>
                </td>
            </tr>
            )}
          </tbody>
        </Table>
      </div>}
    </div>
  );
};

export default DraftAgreements;
