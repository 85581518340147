/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { AiOutlineSearch } from "react-icons/ai";

import {
  ActionIcon,
  Badge,
  Button,
  Input,
  Pagination,
  Select,
  Skeleton,
  Table,
  Text,
  CloseButton,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Direction, Eye, SortAscending, SortDescending } from "tabler-icons-react";
import { format, formatISO } from "date-fns";
import { useDebouncedValue } from "@mantine/hooks";
import {
  getValueForInput,
  loadingStates,
  csvDownload,
  formatDate,
} from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getAgreementInRenewalsList,
  getAgreementTypesApi,
  getAgreementsCsvDownload,
} from "../../utilities/apis/agreements";
import { agreementStatusColors } from "../../utilities/enums";
import Styles from "./AgreementsListingPage.module.css";
import HamburgerMenuAgreements from "../HamburgerMenuAgreements";
import { getBussinessUnit } from "../../utilities/apis/serviceProvider";
import { getPartyNames } from "../../utilities/apis/parties";

const statusOptions = [
  {
    label: "Pending with Company",
    value: "Pending with Company",
  },
  {
    label: "TP",
    value: "TP",
  },
];

const AgreementsExpired = () => {
  const [filters, setFilters] = useState({});
  const [applyfilters, setApplyFilters] = useState(true);
  const [typeOptions, setTypeOptions] = useState([]);
  const [buOptions, setBuOptions] = useState([]);
  const [partyOption, setPartyOptions] = useState([]);
  const [tabName, setTabName] = useState("renewals");
  const [pageRenewals, setPageRenewals] = useState(1);
  const [pageExpired, setPageExpired] = useState(1);
  const [configRenewals, setConfigRenewals] = useState({
    loading: loadingStates.LOADING,
    data: null,
  });
  const [sort, setSort] = useState({
    item: "id",
    direction: "asc"
  });
  const toggleOrder = () => {
    setSort({...sort, direction: sort.direction === "asc" ? "desc" : "asc"});
  };
  let mainData;
  const [configExpired, setConfigExpired] = useState({
    loading: loadingStates.LOADING,
    data: null,
  });

  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const data = await apiWrapWithErrorWithData(getBussinessUnit());
        if (data) {
          setBuOptions(data.businessunitList);
        } else {
          console.error("Invalid business unit response format:", response);
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };
    const getPartyName = async () => {
      const response = await apiWrapWithErrorWithData(getPartyNames());
      if (response.success) {
        console.log("party de rha h bro: ", response);
        setPartyOptions(response.parties);
      }
    };
    const getAgreementTypes = async () => {
      const resp = await apiWrapWithErrorWithData(getAgreementTypesApi());
      if (resp?.success && resp?.typesOfAgreement) {
        const uniqueValues = Array.from(new Set([...resp.typesOfAgreement]));
        const temp = uniqueValues.map((item) => ({ label: item, value: item }));
        setTypeOptions([...temp]);
      }
    };
    getPartyName();
    fetchBusinessUnits();
    getAgreementTypes();
  }, []);

  const fetchExpired = async () => {
    const response = await apiWrapWithErrorWithData(
      getAgreementInRenewalsList({
        page: pageExpired,
        tabName: "expired",
        filterOptions: filters,
        sortByOptions: {
          [sort.item] : sort.direction,
        }
      })
    );
    setConfigExpired({
      loading: loadingStates.NO_ACTIVE_REQUEST,
      data: response,
    });
    console.log("expired: ", response);
  };
  useEffect(() => {
    fetchExpired();
  }, [pageExpired, applyfilters, sort]);

  const fetchRenewals = async () => {
    const response = await apiWrapWithErrorWithData(
      getAgreementInRenewalsList({ page: pageRenewals, tabName: "renewals" , filterOptions: filters, sortByOptions: {
        [sort.item] : sort.direction,
      }})
    );
    setConfigRenewals({
      loading: loadingStates.NO_ACTIVE_REQUEST,
      data: response,
    });
    console.log("renewals: ", response);
  };
  useEffect(() => {
    fetchRenewals();
  }, [pageRenewals, applyfilters, sort]);
  useEffect(() => {
    if (tabName === "renewals") {
      mainData = configRenewals;
    } else if (tabName === "expired") {
      mainData = configExpired;
    }
  }, [tabName, configExpired, configRenewals]);

  if (tabName === "renewals") {
    mainData = configRenewals;
  } else if (tabName === "expired") {
    mainData = configExpired;
  }
  const navigate = useNavigate();
  const onClick = (agreementId) => {
    navigate(`/app/agreements_manager/agreements/details/${agreementId}`);
  };
  let srNo;
  if (tabName === "expired") {
    srNo = (pageExpired - 1) * 10 + 1;
  }
  if (tabName === "renewals") {
    srNo = (pageRenewals - 1) * 10 + 1;
  }

  function convertToISOFormat(dateStr) {
    const [day, month, year] = dateStr.split("-");
    return `${year}-${month}-${day}`;
  }
  const onViewClick = (agreementId) => {
    navigate(`/app/agreements_manager/agreements/details/${agreementId}`);
    window.open(url);
  };
  function getDaysDifference(targetDate) {
    const currentDate = new Date();
    const target = new Date(targetDate);
    const timeDifference = target - currentDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  }

  return (
    <>
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <h1
          style={{
            fontSize: "22px",
            fontWeight: "600",
            letterSpacing: "1px",
            textTransform: "lowercase",
            fontFamily: "lexend",
            whiteSpace: "nowrap",
            paddingBottom: "13px",
            position: "relative",
            color: "black",
          }}
        >
          renewals
        </h1>

        <HamburgerMenuAgreements />
      </div>
      <div className="flex justify-around items-center mt-4 hover:text-co">
        <Button
          className="hover:bg-blue-500 hover:text-white"
          variant="outline"
          color="blue"
          onClick={() => setTabName("renewals")}
        >
          expiring soon
        </Button>
        <Button
          className="hover:bg-red-500 hover:text-white"
          variant="outline"
          color="red"
          onClick={() => setTabName("expired")}
        >
          expired
        </Button>
      </div>
      <div className="w-full mt-4 items-center">
        <div className="flex flex-col">
          <div className="flex flex-row-sm">
            <div className="flex items-center">
              <Select
                searchable
                className="mx-2"
                label="agreement type"
                placeholder="select agreement type"
                data={typeOptions}
                value={filters?.typeOfAgreement || null}
                onChange={(val) => {
                  setFilters({
                    ...filters,
                    typeOfAgreement: val,
                  });
                }}
              />
              <Select
                searchable
                className="mx-2"
                placeholder="select business unit"
                label="business unit"
                data={buOptions}
                value={filters?.mapBusinessunit || null}
                onChange={(val) =>
                  setFilters({
                    ...filters,
                    mapBusinessunit: val,
                  })
                }
              />
              <Select
                searchable
                className="mx-2"
                placeholder="select second party"
                label="second party"
                data={partyOption}
                onChange={(val) => {
                  setFilters({
                    ...filters,
                    OR: [
                      { secondParty: { path: "$.name", string_contains: val } },
                      {
                        secondParty: {
                          path: "$.indi_name",
                          string_contains: val,
                        },
                      },
                      {
                        secondParty: {
                          path: "$.brandName",
                          string_contains: val,
                        },
                      },
                    ],
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="mx-2 mt-4">
          <Button
            onClick={() => setApplyFilters(!applyfilters)}
            className="mr-3"
            color="green"
          >
            search
          </Button>
          <Button
            onClick={() => {
              setFilters({});
              setApplyFilters(!applyfilters);
            }}
            color="red"
          >
            clear
          </Button>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex justify-between my-4">
          {/* <Text>
            {tabName==="renewals"
              ? "expiring soon"
              : "expired"}
          </Text> */}
          <Text></Text>
          <div className="flex gap-4">
            <div className="flex">
              <Select
                value={sort.item}
                onChange={(val) => setSort({...sort, item: val})}
                data={[
                  { label: "created at", value: "createdAt" },
                  { label: "end date", value: "dueDateOfContract" },
                  { label: "type of agreement", value: "typeOfAgreement" },
                  { label: "agreement value", value: "agreementValue" },
                  { label: "ain", value: "id" },
                ]}
              />
              <ActionIcon className="mx-2" 
              onClick={toggleOrder}
              >
                {sort.direction === "asc" ? (
                  <SortAscending size={32} />
                ) : (
                  <SortDescending size={32} />
                )}
              </ActionIcon>
            </div>
            <Button
              className="mx-2 btn btn-success btn-lg float-right"
              // disabled={exportButtonLoading}
              // onClick={handleCsvDownload}
              // color={themeColor(colors.agreement)}
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              export
            </Button>
          </div>
        </div>
      </div>

      {mainData.loading === loadingStates.LOADING && (
        <div className="flex flex-col">
          {Array.from({ length: 10 }).map((i) => (
            <Skeleton key={i} height={30} radius="md" className="my-1 w-full" />
          ))}
          <div className="flex flex-row justify-center">
            <Skeleton height={40} radius="md" className="w-40" />
          </div>
        </div>
      )}
      {mainData.loading === loadingStates.NO_ACTIVE_REQUEST &&
        Boolean(mainData.data?.agreementsCount) && (
          <div className="flex flex-col">
            <Table striped>
              <thead>
                <tr>
                  <th>sr no.</th>
                  <th>ain</th>
                  <th>type</th>
                  <th>business unit</th>
                  <th>second party</th>
                  <th>agreement value</th>
                  <th>start date</th>
                  <th>end date</th>
                  {/* <th>ageing</th> */}
                  {tabName === "renewals" && <th>{"due days"}</th>}
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {mainData.data.agreements
                  .filter(
                    (v) =>
                      v.agreementData.json &&
                      v.agreementData.json.typeOfAgreement
                  )
                  .map((row) => (
                    <tr key={row.id}>
                      <td>{srNo++}</td>
                      <td>{`agreement-${row.id}`}</td>

                      <td>{row.agreementData.json.typeOfAgreement}</td>
                      <td>
                        {row.agreementData?.json?.location
                          ? row.agreementData?.json?.location
                          : row.agreementData?.json?.location}
                      </td>
                      <td>
                        <Text>
                          {row.agreementData?.json?.secondParty?.brandName ||
                            "-"}
                        </Text>
                        {"( " + row.agreementData?.json?.secondParty?.name ||
                          "-"}
                        {")"}
                      </td>
                      <td>{row.agreementValue}</td>
                      <td>{formatDate(row.contractStartDate) || "-"}</td>
                      <td> {formatDate(row.contractEndDate) || "-"}</td>
                      {/* <td>{row.ageing || "-"}</td> */}
                      {tabName === "renewals" && (
                        <td>
                          {getDaysDifference(row.contractEndDate) + "-days"}
                        </td>
                      )}
                      <td>
                        <div className="flex flex-row mr-4">
                          <ActionIcon
                            onClick={() => {
                              onViewClick(row.id);
                            }}
                            color="white"
                          >
                            <Eye color="#46BDE1" size={24} />
                          </ActionIcon>
                          {/* )} */}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}
      {mainData.loading !== loadingStates.LOADING &&
      !mainData.data?.agreementsCount ? (
        <div
          className="flex justify-center items-center"
          style={{
            minHeight: "300px",
          }}
        >
          no agreements
        </div>
      ) : null}
      {mainData.data?.agreementsCount ? (
        <div className="flex flex-row justify-center my-4">
          <Pagination
            page={tabName === "renwals" ? pageRenewals : pageExpired}
            onChange={(page) => {
              if (tabName === "renewals") {
                setPageRenewals(page);
              } else if (tabName === "expired") {
                setPageExpired(page);
              }
            }}
            total={Math.ceil(mainData.data.agreementsCount / 10)}
          />
        </div>
      ) : null}
    </>
  );
};

export default AgreementsExpired;
